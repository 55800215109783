import { getS3ImgUrl } from "../services/env-helper";

const entityTypes = {
  loan_against_property: {
    type: "loan_against_property",
    imgSrc: getS3ImgUrl("/b2c/overview/loan-againt-property.webp"),
    title: "Loan against property"
  },
  home_loan: {
    type: "home_loan",
    title: "Home loan",
    imgSrc: getS3ImgUrl("/b2c/overview/home-loan.webp"),
  },
  credit_card: {
    type: "credit_card",
    title: "Credit card",
    imgSrc: getS3ImgUrl("/b2c/overview/credit-card.webp"),
    disabledBreFlow: true,
    noLoanDetails: true
  },
  credit_score: {
    type: "credit_score",
    title: "Credit score",
    imgSrc: getS3ImgUrl("/b2c/icons/know-about-cibil-hero-image.png"),
  },
  personal_loan: {
    type: "personal_loan",
    title: "Personal loan",
    imgSrc: getS3ImgUrl("/b2c/overview/personal-loan.webp"),
    alt: "pl",
  },
  business_loan: {
    type: "business_loan",
    title: "Business loan",
    imgSrc: getS3ImgUrl("/b2c/overview/business-loan.webp"),
  },
  education_loan: {
    type: "education_loan",
    title: "Education loan",
    imgSrc: getS3ImgUrl("/b2c/overview/education-loan.webp"),
    disabledBreFlow: true,
  },
  car_loan: {
    type: "car_loan",
    title: "Car loan",
    imgSrc: getS3ImgUrl("/b2c/overview/car-loan.webp"),
    disabledBreFlow: true,
  },
  used_car_loan: {
    type: "used_car_loan",
    title: "Userd car loan",
    imgSrc: getS3ImgUrl("/b2c/overview/car-loan.webp"),
    disabledBreFlow: true,
  },
  car_refinance: {
    type: "car_loan",
    title: "Car loan",
    imgSrc: getS3ImgUrl("/b2c/overview/car-loan.webp"),
    disabledBreFlow: true,
  },
  gold_loan: {
    type: "gold_loan",
    title: "Gold loan",
    imgSrc: getS3ImgUrl("/b2c/overview/gold-loan.png"),
    disabledBreFlow: true,
  },
  bill_payment: {
    type: "bill_payment",
    title: "Bill payment",
    bannerImg: getS3ImgUrl("/b2c/integration/paying-bills-concept.png")
  },
};

const entityTypesArr = Object.keys(entityTypes).join(",");

const clientTypes = {
  b2c_web: "b2c",
  b2b_web: "b2b",
  b2c_app: "b2c",
};

const clientTypesArr = Object.keys(clientTypes).join(",");

const eventTypes = {
  sdk_invoked: "sdk_invoked",
  sdk_closed: "sdk_closed",
  sdk_initiated: "sdk_initiated",
};

const productTypeIds = {
  check_eligibility: 0,
};

const picklistKeys = {
  address: "Address",
  business_type: "Business Type"
};

const picklistKeysAlias = {
  residential_addess: "residential_addess",
  office_address: "office_address",
};

export {
  entityTypes,
  entityTypesArr,
  clientTypes,
  clientTypesArr,
  eventTypes,
  productTypeIds,
  picklistKeys,
  picklistKeysAlias,
};
